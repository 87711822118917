import Vue from 'vue'
import App from './App.vue'
// import { SurveyVue } from 'survey-vue'

// const app = createApp(App)

// app.component('survey', SurveyVue)





Vue.config.productionTip = false

new Vue({
  render: function (h) { return h(App) },
}).$mount('#app')
