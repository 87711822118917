<template>
  <survey :survey="survey"></survey>
</template>
<script>
import { StylesManager } from "survey-core";
import "survey-vue/defaultV2.min.css";
import * as SurveyVue from "survey-vue";
import { updateClientData } from "@/api/AZTableService";
import { uuidv4 } from "@/common/utils";
import { survey_questions } from "@/survey_questions";

StylesManager.applyTheme("defaultV2");

export default {
  name: "SurveyComp",
  components: {
    survey: SurveyVue.Survey,
  },
  data() {
    const u = new URL(window.location);
    const prof_boundaries = "3667eaec-3cbb-41b0-8ad4-104368559142"; // Do not delete this survey !
    let survey_id = prof_boundaries;
    let survey_id2 = "";
    if (u.search.length > 36) {
      survey_id2 = u.search.substring(1).split("=")[1];
      if (survey_id.length == 36) {
        survey_id = survey_id2;
        console.log("replaced default survey with " + survey_id);
      }
    }

    let survey_model = {};
    if (survey_id2 == prof_boundaries) {
      survey_model = new SurveyVue.Model(survey_questions);
    } else {
      survey_model = new SurveyVue.Model({ surveyId: survey_id });
    }

    // Replace with your survey's JSON definition
    // questions: [
    //   {
    //     type: "checkbox",
    //     name: "car",
    //     title: "What car are you driving?",
    //     isRequired: true,
    //     choices: [
    //       "None",
    //       "Ford",
    //       "Vauxhall",
    //       "Volkswagen",
    //       "Nissan",
    //       "Audi",
    //     ],
    //   },
    // ],

    return {
      surveyid: survey_id,
      survey: survey_model,
    };
  },

  mounted() {
    if (this.survey === undefined) {
      return;
    }

    const me = this;
    this.survey.onLoadedSurveyFromService.add(function (survey) {
      console.log(survey);
      const closed = me.survey.getQuestionByName("SurveyClosedDate").value;

      const closed_date = Date.parse(closed);
      const today = new Date();
      if (today > closed_date) {
        const closedJSON = {
          title: "Survey is Closed",
          logoPosition: "right",
          pages: [
            {
              name: "page1",
              elements: [
                {
                  type: "html",
                  name: "Closed",
                  html: "<h1>\nThis survey is closed. <p> &nbsp;<p>\nThank you.\n</h1>",
                },
              ],
            },
          ],
          showCompletedPage: false,
        };
        me.survey = new SurveyVue.Model(closedJSON);
      }
    });

    this.survey.onComplete.add(function (survey, options) {
      console.log("survet options", options);
      // survey.surveyShowDataSaving = true;

      let dataObj = {
        PartitionKey: me.surveyid,
        RowKey: uuidv4(), //new Date(),
        SurveyData: JSON.stringify(me.survey.data),
      };
      updateClientData(dataObj);

      // survey.surveyShowDataSaving = false;
      // remove the button to save incomplete survey
    });
  },
};
</script>