
import { doPostAction } from "./RESTClient";

// const DB_ENTITY_NAME = "professionalboundaries"
const PARTITION_KEY ="PartitionKey";
const ROW_KEY = "RowKey"
const UPSERT_URL = "https://prod-19.australiasoutheast.logic.azure.com:443/workflows/64dda7fe713142de957eb3e780af0d66/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=70TZqS1CfoW1Cj90o998W-sgH2BMiTJC-FFr-8ZdvlY";

export function updateClientData(
    rowData,
    // entity = DB_ENTITY_NAME
  ) {
    //const azTableJSON = toAZDataStructure(rowData);
    let data = {
      SurveyData: rowData["SurveyData"],
       
      PartitionKey: rowData[PARTITION_KEY],
      RowKey: rowData[ROW_KEY]
    };
    return doPostAction(UPSERT_URL, data);
  }

  
